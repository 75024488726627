import styled from "styled-components";
import type { LogoProps } from "./LogoProps";
import Logo from "./graphics/bankid-main_140x21.svg";

const BankIdLogo = styled(function ({ width = 180, ...props }: LogoProps) {
  return (
    <img
      {...props}
      src={Logo}
      alt="BankID"
      width={width}
      height={(21 / 140) * width}
    />
  );
})``;

export default BankIdLogo;
